import { api } from 'common/store/api';
import { MyCompaniesResponse } from 'common/types/Company';

const MY_COMPANY = '/companies';

const apiWithTag = api.enhanceEndpoints({
  addTagTypes: ['Company', 'Companies'],
});

const companyEndpoints = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getMyCompanies: builder.query<MyCompaniesResponse, void>({
      query: () => ({ url: MY_COMPANY }),
      providesTags: ['Companies', 'Company'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetMyCompaniesQuery } = companyEndpoints;
