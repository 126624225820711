import { useRouter } from 'next/router';
import {
  NavLink,
  ContentWrapper,
  NavLinkBackground,
  NavItemIconContainer,
} from './NavBar.styles';
import Typography from '../../Typography';
import type { NavItem as NavItemType } from './NavBar';

function NavItem({ title, href, icon }: NavItemType) {
  const router = useRouter();

  const isActive = router.asPath.startsWith(href);
  return (
    <NavLink href={href} $isActive={isActive}>
      {isActive && (
        <NavLinkBackground layoutId="background" layout inherit={false} />
      )}
      <ContentWrapper>
        <NavItemIconContainer>{icon}</NavItemIconContainer>
        <Typography color="currentColor" variant="ctaSmall">
          {title}
        </Typography>
      </ContentWrapper>
    </NavLink>
  );
}

export default NavItem;
