import { AccountRole } from 'common/types/AccountRole';
import { CompanyManagerRole } from 'common/types/CompanyManager';

export const CompanyManagerRoleLabels: Record<
  CompanyManagerRole,
  {
    short: string;
    full: string;
  }
> = {
  [CompanyManagerRole.COMPANY_ADMIN]: {
    short: 'Admin',
    full: 'Company Admin',
  },
  [CompanyManagerRole.COMPANY_TEAM_MEMBER]: {
    short: 'Team Member',
    full: 'Company Team Member',
  },
  [CompanyManagerRole.COMPANY_OWNER]: {
    short: 'Owner',
    full: 'Company Owner',
  },
};

export const AccountRoleLabels: Record<
  AccountRole.ADMIN | AccountRole.COORDINATOR,
  string
> = {
  [AccountRole.ADMIN]: 'Wreno Admin',
  [AccountRole.COORDINATOR]: 'Wreno Team Member',
};
