import styled from 'styled-components';
import { motion } from 'framer-motion';

export const LogoContainer = styled.div`
  padding: 0 8px;
  @media ${({ theme }) => theme.breakpoints.large} {
    margin-bottom: 24px;
  }
`;

export const NavBarContainer = styled.div`
  flex: 1;
  padding: 0 8px;
`;

export const MobileNavContainer = styled(motion.aside)`
  position: absolute;
  transition: all 1s;
  inset: 0;
  top: ${({ theme }) => `${theme.variables.headerHeightMobile}px`};
  display: flex;
  z-index: 99;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.black};
  padding: 10px 12px 30px;
  gap: 20px;
  flex-direction: column;
`;

export const Container = styled.aside`
  display: flex;
  z-index: 2;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.black};
  padding: 30px 12px;
  flex-direction: column;
`;

export const MobileHeader = styled.header`
  background: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
`;

export const NavIconContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  svg {
    height: 20px;
    width: 20px;
  }
`;
