import Link from 'next/link';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Nav = styled.nav`
  flex: 1;
  background: ${({ theme }) => theme.colors.black};
  padding: 10px 0 10px;
`;

export const NavLink = styled(Link)<{ $isActive: boolean }>`
  position: relative;
  margin-bottom: 30px;
  color: ${(props) => props.theme.colors.gray400};
  text-decoration: none;
  height: 32px;
  display: flex;
  align-items: center;
  &:last-of-type {
    margin-bottom: 0;
  }
  ${({ $isActive, theme }) => $isActive && `color: ${theme.colors.white};`}
`;

export const NavItemIconContainer = styled.div`
  svg {
    flex-shrink: 0;
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }
`;

export const NavLinkBackground = styled(motion.div)`
  ${({ theme }) => `
    position: absolute;
    top: -9px;
    left: -10px;
    bottom: -9px;
    right: -10px;
    z-index: 0;
    background: ${theme.colors.gray900};
    border-radius: 5px;
  `}
`;

export const ContentWrapper = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
  height: 100%;
`;
