import { useMemo } from 'react';
import type { ReactNode } from 'react';
import type { BreadcrumbsProps } from 'styleguide/src/components/Breadcrumb';
import ProjectJobsIcon from 'styleguide/src/components/icons/ProjectJobsIcon';
import UserManagementIcon from 'styleguide/src/components/icons/UserManagementIcon';
import UserIcon from 'styleguide/src/components/icons/UserIcon';
import ChatIcon from 'styleguide/src/components/icons/ChatIcon';
import MoreIcon from 'styleguide/src/components/icons/MoreIcon';
import { AccountRole } from 'common/types/AccountRole';
import usePubNubAppData from 'hooks/pubnub/usePubNubAppData';
import AppShell from 'common/components/appShell/AppShell';

interface Props {
  children: ReactNode | undefined;
  breadcrumbs?: BreadcrumbsProps;
}

function AppShellWrapper({ children, breadcrumbs }: Props) {
  const { unreadChats } = usePubNubAppData();

  const navItems = useMemo(
    () => [
      {
        href: '/jobs-and-projects',
        title: 'Projects & Jobs',
        icon: <ProjectJobsIcon />,
      },
      {
        href: '/chat',
        title: 'Chat',
        icon: <ChatIcon />,
        notification: unreadChats,
      },
      {
        href: '/user-management',
        title: 'User Management',
        icon: <UserManagementIcon />,
      },
      {
        href: '/recruiting',
        title: 'Recruiting',
        icon: <UserIcon />,
      },
      {
        href: '/tags',
        title: 'Tags',
        icon: <MoreIcon />,
        roleRestriction: [AccountRole.ADMIN],
      },
    ],
    [unreadChats],
  );
  return (
    <AppShell
      navItems={navItems}
      breadcrumbs={breadcrumbs}
      baseUrl="/user-management"
    >
      {children}
    </AppShell>
  );
}

export default AppShellWrapper;
