import { ReactNode } from 'react';
import useRoles from '../../../hooks/useRoles';
import useAccount from 'common/hooks/useAccount';
import Nav from 'styleguide/src/components/AppShell/Nav/Nav';
import { AccountRole } from 'common/types/AccountRole';
import useCompanyManagerRoles from 'common/hooks/useCompanyManagerRoles';
import { CompanyManagerRoleLabels } from 'common/mappings/companyManager';

interface SidebarProps {
  navItems: Array<{
    title: string;
    href: string;
    icon: ReactNode;
    roleRestriction?: Array<AccountRole>;
  }>;
}

function Sidebar({ navItems }: SidebarProps) {
  const { role } = useRoles();
  const { user, isLoading, logout } = useAccount();

  const { isLoading: loading, companyManagerRole } = useCompanyManagerRoles();

  const navLinks = navItems?.filter((navItem) =>
    navItem.roleRestriction && role
      ? navItem.roleRestriction?.includes(role)
      : true,
  );

  return (
    <Nav
      navLinks={navLinks}
      user={user}
      secondaryRole={CompanyManagerRoleLabels[companyManagerRole]?.full}
      isLoading={isLoading || loading}
      dropdownItems={[
        {
          label: 'Logout',
          onSelect: logout,
        },
      ]}
    />
  );
}

export default Sidebar;
