import type { SVGAttributes } from 'react';

const UserIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.0004 3.00016C12.4671 3.00016 13.6671 4.20016 13.6671 5.66683C13.6671 7.1335 12.4671 8.3335 11.0004 8.3335C9.53374 8.3335 8.33374 7.1335 8.33374 5.66683C8.33374 4.20016 9.53374 3.00016 11.0004 3.00016ZM11.0004 16.3335C14.6004 16.3335 18.7337 18.0535 19.0004 19.0002H3.00041C3.30707 18.0402 7.41374 16.3335 11.0004 16.3335ZM11.0004 0.333496C8.05374 0.333496 5.66707 2.72016 5.66707 5.66683C5.66707 8.6135 8.05374 11.0002 11.0004 11.0002C13.9471 11.0002 16.3337 8.6135 16.3337 5.66683C16.3337 2.72016 13.9471 0.333496 11.0004 0.333496ZM11.0004 13.6668C7.44041 13.6668 0.33374 15.4535 0.33374 19.0002V21.6668H21.6671V19.0002C21.6671 15.4535 14.5604 13.6668 11.0004 13.6668Z"
      fill="currentColor"
    />
  </svg>
);

export default UserIcon;
