import styled from 'styled-components';

export const Container = styled.div`
  height: 100dvh;
  background: ${(props) => props.theme.colors.white};
  display: grid;
  grid-template-rows: ${({ theme }) =>
      `${theme.variables.headerHeightMobile}px`} 1fr;
  grid-template-columns: 1fr;
  @media ${({ theme }) => theme.breakpoints.large} {
    grid-template-rows: 1fr;
    grid-template-columns: 200px 1fr;
  }
`;

export const Main = styled.main<{ $immersive?: boolean }>`
  position: relative;
  padding: 16px;
  overflow-y: auto;
  grid-column-start: 1;
  grid-row-start: 2;

  ${({ $immersive }) =>
    $immersive &&
    `
    padding: 0;
  `}

  @media ${({ theme }) => theme.breakpoints.large} {
    grid-column-start: 2;
    grid-row-start: 1;
    padding: ${({ $immersive }) => ($immersive ? '0' : '32px 40px')};
  }
`;

export const Sidebar = styled.aside`
  position: relative;
  z-index: 2;
  box-shadow: 1px 0 5px rgba(161, 166, 161, 0.25);
  border-radius: 0 20px 0 0;
  background: ${(props) => props.theme.colors.machineGreen100};

  @media ${({ theme }) => theme.breakpoints.large} {
    grid-column-start: 1;
    grid-row-start: 2;
  }
`;
