import type { SVGAttributes } from 'react';

const UserManagementIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 18 20"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor">
      <path d="m13.5368 0c.1913.054645.3552.0819676.5465.163935 1.0929.409838 1.7213 1.530065 1.5027 2.677605-.2459 1.17487-1.2568 1.96722-2.4864 1.91258-1.0929-.05465-2.0491-.95629-2.1858-2.10384-.1366-1.25683.7104-2.377055 1.9673-2.5956348.0273 0 .0546-.0273226.0819-.0273226.1913-.0273225.3825-.0273226.5738-.0273226zm1.1475 2.37706c0-.76503-.6284-1.420772-1.4207-1.420772-.7651 0-1.4208.655742-1.4208 1.420772 0 .79235.6557 1.42077 1.4481 1.42077.765-.02732 1.3934-.65574 1.3934-1.42077z" />
      <path d="m10.2859 20c-1.2295 0-2.48633 0-3.71584 0-.62842 0-.95629-.3278-.95629-.9563 0-.9016 0-1.8032 0-2.7049 0-1.53.95629-2.7596 2.4317-3.1147.21858-.0547.46448-.082.68306-.082 1.0929 0 2.21317-.0273 3.30607.0273 1.5027.0547 2.8142 1.3388 2.8688 2.8689.082 1.0109.082 2.0492.082 3.0874 0 .5465-.3552.8743-.9017.8743-1.2841 0-2.5409 0-3.7978 0zm3.7432-.9563c0-.9836.0273-1.9672 0-2.9235-.0273-1.0929-.9563-1.9945-2.0219-2.0218-1.1202-.0273-2.24041-.0273-3.36063 0-1.0929.0273-1.99455.9016-2.04919 1.9945-.05465.9563-.02732 1.9126-.02732 2.8962 0 .0273 0 .0546.02732.082h.81967c0-.082 0-.164 0-.2186 0-.7924 0-1.5847 0-2.3771 0-.0819 0-.1639.02733-.2459.05464-.1912.19125-.3005.38251-.3005s.32787.1366.35519.3279v.2185 2.4044.2186h4.18032c0-.082 0-.1639 0-.2186 0-.7923 0-1.5847 0-2.3497 0-.082 0-.1913.0273-.2732.0547-.1913.1913-.3006.3826-.3006.1912 0 .3278.1366.3552.3279v.2186 2.4043.2186c.3278-.082.6011-.082.9016-.082z" />
      <path d="m15.3399 11.3663c0-.2459 0-.4645 0-.6831 0-.6557 0-1.31149 0-1.93991 0-.08196 0-.16393.0273-.2459.0546-.19126.1639-.27322.3552-.27322.1912 0 .3005.08196.3552.27322.0273.08197.0273.19126.0273.27323v2.62298h.847c0-.0273 0-.082 0-.1366 0-.8744 0-1.776 0-2.65032 0-1.0929-.765-1.96722-1.8306-2.15848-.082-.02732-.1913-.02732-.3006-.02732-1.1202 0-2.2131 0-3.3333 0-.6011 0-1.0929.27323-1.50274.73771-.05464.05464-.08196.10929-.13661.13661-.19126.21858-.46448.2459-.65574.08197-.21858-.16394-.2459-.46448-.05464-.68306.27322-.27323.54645-.54645.84699-.76503.49184-.3552 1.06554-.46449 1.63934-.46449 1.1202 0 2.2405-.02732 3.388.02733 1.5027.05464 2.8142 1.39344 2.8689 2.9235.0273 1.03826 0 2.04918 0 3.08748 0 .4918-.3279.8196-.847.847-1.3662 0-2.7323 0-4.0984 0-.3279 0-.5465-.1913-.5465-.4645s.2186-.4645.5465-.4645c.8197-.0546 1.612-.0546 2.4044-.0546z" />
      <path d="m.968431 14.5082h.846999c0-.082 0-.1367 0-.2186 0-.7924 0-1.612 0-2.4044 0-.082 0-.1366 0-.2186.02732-.1912.19126-.3279.35519-.3279.19126 0 .32787.1093.38252.3006.02732.082.02732.1639.02732.2459v2.3771.2459h.32787 2.02186c.38252 0 .6011.3005.49181.6284-.08197.1912-.24591.3005-.54645.3005-1.12022 0-2.24045 0-3.38799 0-.21858 0-.43716 0-.655742 0-.464482-.0273-.8196747-.3278-.8196747-.8197 0-1.0655-.0273225-2.1311 0-3.1967.054645-1.42075 1.3661267-2.75955 2.7868967-2.8142 1.22951-.05464 2.48634-.02732 3.71586 0 .27322 0 .54645.10929.81967.19126.27323.08196.38252.35519.30055.60109s-.32787.38252-.6011.27323c-.40983-.13662-.81967-.16394-1.22951-.16394-.95628 0-1.88525-.02732-2.84154 0-1.12022.02732-2.021862.95626-2.049185 2.07656-.027322.9289 0 1.8579 0 2.7868.054645.082.054646.1093.054646.1367z" />
      <path d="m4.71202 3.16943c1.31148 0 2.34973 1.06558 2.34973 2.34974 0 1.31148-1.06558 2.37706-2.37705 2.37706-1.31148 0-2.34974-1.06558-2.34974-2.34974 0-1.3388 1.06558-2.37706 2.37706-2.37706zm-1.44809 2.34974c0 .79235.65574 1.44809 1.42077 1.42077.76502 0 1.42077-.65574 1.42077-1.42077s-.65575-1.42077-1.42077-1.42077c-.76503 0-1.39345.65574-1.42077 1.42077z" />
      <path d="m7.93604 10.0548c0-1.31149 1.03825-2.37707 2.34976-2.37707 1.3114 0 2.377 1.03826 2.377 2.34977 0 1.2841-1.0656 2.3497-2.3497 2.377-1.31149.0274-2.37706-1.0382-2.37706-2.3497zm.95628.0273c0 .7924.65574 1.4208 1.42078 1.4208.7923 0 1.4208-.6558 1.4208-1.4208 0-.79234-.6558-1.42076-1.4208-1.42076-.79236-.02732-1.42078.62842-1.42078 1.42076z" />
    </g>
  </svg>
);

export default UserManagementIcon;
