import Typography from '../Typography';
import type { Account } from 'common/types/Account';
import { USER_ROLE_MAP } from 'common/utils/mappings/account';
import {
  ProfileContainer,
  ProfileLink,
  ProfilePicture,
  Details,
  Ellipsis,
} from './Profile.styles';

type Option = {
  fullName: boolean;
  fullNameAbbrev: boolean;
  email: boolean;
  role: boolean;
  baseUrl: string;
};

export type ProfileProps = {
  user: Partial<Account>;
  secondaryRole?: string;
  options?: Partial<Option>;
  className?: string;
};

const Profile = ({ className, user, options, secondaryRole }: ProfileProps) => {
  const ProfileDetails = () => (
    <Typography variant="eyebrowSmall" color="inherit">
      <Ellipsis>
        {user?.firstName || ''}{' '}
        {options?.fullName
          ? user?.lastName
            ? user?.lastName
            : ''
          : user?.lastName
            ? `${user?.lastName?.charAt(0)}.`
            : ''}
      </Ellipsis>
    </Typography>
  );
  return (
    <ProfileContainer className={className}>
      <ProfilePicture
        firstName={user?.firstName}
        {...(user?.company?.logo ? { url: user?.company?.logo } : {})}
      />
      <Details>
        {options?.baseUrl ? (
          <ProfileLink href={`${options?.baseUrl}/${user?.id}`}>
            <ProfileDetails />
          </ProfileLink>
        ) : (
          <>
            <ProfileDetails />
          </>
        )}
        {options?.role && (
          <Typography variant="labelStandard" color="inherit">
            {secondaryRole
              ? secondaryRole
              : user?.role
                ? USER_ROLE_MAP[user.role].labels.singular
                : 'N/A'}
          </Typography>
        )}
        {options?.email && (
          <Typography variant="labelStandard" color="inherit">
            {user?.email ? user.email : ''}
          </Typography>
        )}
      </Details>
    </ProfileContainer>
  );
};

export default Profile;
