import { ReactElement } from 'react';
import styled from 'styled-components';
import Tooltip, { TooltipProps } from '../Tooltip';
import { CTAStandard } from '../Typography/Typography.styles';

const StyledLabel = styled.label`
  display: block;
  transition: color 0.2s ease;
  ${CTAStandard};
`;

const LabelWrapper = styled.div<{
  $focused?: boolean;
  $error?: boolean;
  $disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  color: ${({ theme, $error, $disabled, $focused }) =>
    $error
      ? theme.colors.alertRed
      : $disabled
        ? theme.colors.gray400
        : $focused
          ? theme.colors.machineGreen600
          : theme.colors.black};
`;

// Ensures backwards compatibility with string, react element, or tooltip settings (with title and content)
export type HelperTextProp = string | ReactElement | TooltipProps | undefined;

interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
  error?: boolean;
  focused?: boolean;
  disabled?: boolean;
  className?: string;
  helperText?: HelperTextProp;
}

export default function Label({
  error,
  focused,
  disabled,
  helperText,
  children,
  className,
  ...props
}: Props) {
  return (
    <LabelWrapper
      className={`Wreno__Label--container ${className || ''}`}
      $focused={focused}
      $error={error}
      $disabled={disabled}
    >
      <StyledLabel {...props}>{children}</StyledLabel>
      {getTooltip(helperText, disabled)}
    </LabelWrapper>
  );
}

function getTooltip(helperText: HelperTextProp, disabled?: boolean) {
  if (!helperText) {
    return null;
  }
  if (typeof helperText === 'object' && 'content' in helperText) {
    return <Tooltip disabled={disabled} {...helperText} />;
  }
  return <Tooltip disabled={disabled} content={helperText} />;
}
