import { Account } from './Account';
import { AccountRole } from './AccountRole';
import { Company } from './Company';

export enum CompanyManagerRole {
  COMPANY_OWNER = 'COMPANY_OWNER',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  COMPANY_TEAM_MEMBER = 'COMPANY_TEAM_MEMBER',
}

export type CompanyManagerPermissionFlags = {
  accessOpportunities: boolean;
  modifyCompanyUsers: boolean;
  inviteNewVendors: boolean;
  inviteExistingVendors: boolean;
};

export enum CompanyManagerPermission {
  VENDOR_MANAGEMENT_INVITE_EXISTING = 'vendor_management.invite_existing',
  COMPANY_MANAGEMENT_MODIFY_USERS = 'company_management.modify_users',
  VENDOR_MANAGEMENT_ADD_NEW = 'vendor_management.add_new',
  OPPORTUNITIES_ACCESS = 'opportunities.access',
}

export type CompanyManager = {
  companyId: number;
  userId: string;
  user?: Pick<
    Account,
    'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
  >;
  company?: Company;
  role: CompanyManagerRole;
  permissionFlags: CompanyManagerPermissionFlags;
  permissions: CompanyManagerPermission[];
};

export type CompanyManagerUser = Pick<
  Account,
  'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'lastSignInDate'
> & {
  companyManagers: CompanyManager[];
  role:
    | AccountRole.PROPERTY_MANAGER
    | AccountRole.ADMIN
    | AccountRole.COORDINATOR;
};
