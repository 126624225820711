import theme from 'styleguide/src/themes/theme';
import { AllPossibleAccountRoles } from 'common/types/AccountRole';

export type AccountStatus = {
  [key in AllPossibleAccountRoles]: {
    color: string;
    labels: { plural: string; singular: string };
  };
};

export const USER_ROLE_MAP: AccountStatus = {
  ALL: { color: '#132013', labels: { singular: 'All', plural: 'All' } },
  WRENNER: {
    color: '#8CDC5F',
    labels: { singular: 'Wrenner', plural: 'Wrenners' },
  },
  VENDOR: {
    color: '#8CDC5F',
    labels: { singular: 'Vendor', plural: 'Vendors' },
  },
  TRADE_PARTNER: {
    color: '#3CA505',
    labels: { singular: 'Trade Partner', plural: 'Trade Partners' },
  },
  PROPERTY_MANAGER: {
    color: '#3CA505',
    labels: { singular: 'Property Manager', plural: 'Property Managers' },
  },
  COORDINATOR: {
    color: '#C8A679',
    labels: { singular: 'Coordinator', plural: 'Coordinators' },
  },
  ADMIN: { color: '#AC7A49', labels: { singular: 'Admin', plural: 'Admins' } },
  PENDING_ACTIVATION: {
    color: theme.colors.machineGreen600,
    labels: { singular: 'Pending Activation', plural: 'Inactivate' },
  },
} as const;
