import { ReactNode } from 'react';
import { LayoutGroup } from 'framer-motion';
import NavItem from './NavBarItem';
import { Nav } from './NavBar.styles';

export type NavItem = {
  title: string;
  href: string;
  icon: ReactNode;
};

export interface NavBarProps {
  items: NavItem[];
}

export default function NavBar({ items }: NavBarProps) {
  return (
    <Nav>
      <LayoutGroup>
        {items.map((navItem, index) => (
          <NavItem
            key={`${navItem.title}_${index}`}
            title={navItem.title}
            href={navItem.href}
            icon={navItem.icon}
          />
        ))}
      </LayoutGroup>
    </Nav>
  );
}
