import styled from 'styled-components';

export const PhonePictureContainer = styled.div`
  flex-shrink: 0;
  width: 45px;
  height: 45px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.gray400};
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

export const PhonePictureImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`;

export const PhonePictureFirstLetter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.black};
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
`;
