import styled from 'styled-components';

export const ProfileContainer = styled.div`
  overflow: hidden;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
`;

export const ContextMenuWrapper = styled.div`
  .context_menu_button_wrapper {
    width: 100%;
  }
`;
