import styled from 'styled-components';
import { Root, Trigger, Content, Arrow } from '@radix-ui/react-popover';
import { LabelStandard } from '../Typography/Typography.styles';

export const TooltipWrapper = styled(Root)``;

export const TooltipArrow = styled(Arrow)<{ $variant?: 'dark' | 'light' }>`
  fill: ${(props) => props.theme.colors.gray100};
  filter: drop-shadow(0 1px ${(props) => props.theme.colors.gray300});

  ${({ $variant, theme }) =>
    $variant === 'dark' &&
    `
    fill: ${theme.colors.black};
    `}
`;

export const TooltipIconWrapper = styled(Trigger)`
  background: transparent;
  border: none;
  width: auto;
  height: auto;
  display: inline;
  padding: 1px 8px;
  color: inherit;

  :not(:focus-visible) {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const IconInnerWrapper = styled.div`
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const TooltipContent = styled(Content)`
  background: ${(props) => props.theme.colors.gray100};
  box-shadow: 0 0 0 1px ${(props) => props.theme.colors.gray300};
  border-radius: 10px;
  outline: none;
  max-width: 225px;
  overflow: hidden;
  z-index: 11;
`;

export const InnerContentWrapper = styled.div`
  padding: 10px 12px 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-wrap: balance;
  margin-trim: block;
  ${LabelStandard};
  & p {
    margin: 0;
  }
`;
