import type { SVGAttributes } from 'react';

const ChatIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    fill="none"
    height="18"
    viewBox="0 0 19 18"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor">
      <path d="m5.57011 17.1c-.29799 0-.57306-.0688-.84813-.2292-.55013-.3209-.84812-.8711-.84812-1.49v-2.1088h-.64182c-1.78794 0-3.23204-1.4441-3.23204-3.2321v-6.80786c0-1.78794 1.4441-3.23204 3.23204-3.23204h11.89666c1.7879 0 3.232 1.4441 3.232 3.23204v6.80786c0 1.788-1.4441 3.2321-3.232 3.2321h-2.5215l-6.18897 3.5759c-.27507.1604-.57305.2521-.84812.2521zm-2.33807-15.95389c-1.14611 0-2.08593.93982-2.08593 2.08593v6.80786c0 1.1462.93982 2.086 2.08593 2.086h1.21488c.32091 0 .57305.2521.57305.573v2.6819c0 .298.18338.4356.27507.4814s.29799.1375.55013 0l6.32653-3.6446c.0917-.0459.1834-.0688.2751-.0688h2.6819c1.1461 0 2.0859-.9398 2.0859-2.0859v-6.83086c0-1.14611-.9398-2.08593-2.0859-2.08593z" />
      <path d="m14.5326 5.79943h-10.70466c-.32091 0-.57306-.25214-.57306-.57305s.25215-.57306.57306-.57306h10.70466c.3209 0 .5731.25215.5731.57306s-.2522.57305-.5731.57305z" />
      <path d="m11.9195 8.80212h-8.09156c-.32091 0-.57306-.25215-.57306-.57306s.25215-.57305.57306-.57305h8.09156c.3209 0 .5731.25214.5731.57305s-.2522.57306-.5731.57306z" />
    </g>
  </svg>
);

export default ChatIcon;
