import { Account } from 'common/types/Account';
import ContextMenu, { ContextMenuType } from '../../ContextMenu';
import Loader from '../../Loader';
import Profile from '../../Profile';
import { ProfileContainer, ContextMenuWrapper } from './ProfileBar.styles';

export interface ProfileBarProps {
  isLoading: boolean;
  dropdownItems: ContextMenuType[];
  user?: Partial<Account>;
  secondaryRole?: string;
}

export default function ProfileBar({
  isLoading,
  dropdownItems,
  user,
  secondaryRole,
}: ProfileBarProps) {
  return (
    <ContextMenuWrapper>
      <ContextMenu align="start" items={dropdownItems}>
        {!user || isLoading ? (
          <Loader inline size="30px" color="white" />
        ) : (
          <ProfileContainer>
            <Profile
              user={user}
              secondaryRole={secondaryRole}
              options={{ role: true }}
            />
          </ProfileContainer>
        )}
      </ContextMenu>
    </ContextMenuWrapper>
  );
}
