import styled from 'styled-components';
import Link from '../Link';
import { LabelLarge } from '../Typography/Typography.styles';

export const Bread = styled.div`
  display: flex;
`;

export const Container = styled.div``;

export const BreadCrumbLink = styled(Link)<{ active?: boolean }>`
  color: ${(props) =>
    props.active ? props.theme.colors.black : props.theme.colors.gray800};
  ${LabelLarge};
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.colors.black};
  }
`;

export const BreadCrumbLinkButton = styled.button<{ active?: boolean }>`
  color: ${(props) =>
    props.active ? props.theme.colors.black : props.theme.colors.gray400};
  font-weight: ${(props) => (props.active ? 700 : 400)};
  font-size: 12px;
  line-height: 16px;
  border: none;
  background: transparent;
  padding: 0;
  &:hover {
    color: ${(props) => props.theme.colors.black};
    cursor: pointer;
  }
`;

export const Seperator = styled.span`
  padding: 0px 4px;
  color: ${(props) => props.theme.colors.gray400};
  ${LabelLarge};
`;
