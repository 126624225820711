import Link from 'next/link';
import {
  MobileNavContainer,
  LogoContainer,
  MobileHeader,
  NavBarContainer,
  NavIconContainer,
} from './Nav.styles';
import Image from 'next/image';
import NavBar from '../NavBar/NavBar';
import ProfileBar from '../ProfileBar/ProfileBar';
import WrenoNavLogo from './WrenoNavLogo.svg';
import { NavProps } from './Nav';
import { useState } from 'react';
import FocusLock from 'react-focus-lock';
import CloseIcon from '../../icons/CloseIcon';
import MenuIcon from '../../icons/MenuIcon';
import { AnimatePresence } from 'framer-motion';

export default function MobileNav(props: NavProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <MobileHeader>
        <LogoContainer>
          <Link href={props.navLinks[0]?.href || '/'}>
            <Image src={WrenoNavLogo} priority alt="Wreno Logo" />
          </Link>
        </LogoContainer>
        <NavIconContainer>
          {isOpen ? (
            <CloseIcon onClick={() => setIsOpen(false)} />
          ) : (
            <MenuIcon onClick={() => setIsOpen(true)} />
          )}
        </NavIconContainer>
      </MobileHeader>
      <MenuContent {...props} isOpen={isOpen} />
    </>
  );
}

function MenuContent({
  navLinks,
  dropdownItems,
  isLoading,
  user,
  isOpen,
  secondaryRole,
}: NavProps & { isOpen: boolean }) {
  return (
    <AnimatePresence>
      {isOpen && (
        <FocusLock>
          <MobileNavContainer
            initial={{ opacity: 0, x: 200 }}
            animate={{
              opacity: 1,
              x: 0,
              transition: {
                duration: 0.05,
              },
            }}
            exit={{ opacity: 0, x: 200 }}
            transition={{ duration: 0.3 }}
          >
            <NavBarContainer>
              <NavBar items={navLinks || []} />
            </NavBarContainer>
            <ProfileBar
              dropdownItems={dropdownItems}
              isLoading={isLoading}
              user={user}
              secondaryRole={secondaryRole}
            />
          </MobileNavContainer>
        </FocusLock>
      )}
    </AnimatePresence>
  );
}
