import type { SVGAttributes } from 'react';

const MoreIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width={20}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 16"
    {...props}
  >
    <path
      d="M6.667 9.033a.904.904 0 0 0 .698-.317 1.039 1.039 0 0 0 0-1.433.906.906 0 0 0-.698-.316c-.254 0-.477.105-.667.316a1.044 1.044 0 0 0 0 1.433c.19.212.413.317.667.317Zm4.444 0a.904.904 0 0 0 .698-.317 1.039 1.039 0 0 0 0-1.433.906.906 0 0 0-.698-.316c-.254 0-.476.105-.667.316a1.044 1.044 0 0 0 0 1.433c.19.212.413.317.667.317Zm4.445 0a.904.904 0 0 0 .698-.317 1.04 1.04 0 0 0 0-1.433.906.906 0 0 0-.698-.316c-.254 0-.477.105-.667.316a1.044 1.044 0 0 0 0 1.433c.19.212.413.317.667.317ZM0 8l4.54-6.767a2.8 2.8 0 0 1 .984-.9A2.648 2.648 0 0 1 6.825 0h11.143c.572 0 1.053.211 1.445.633.391.423.587.934.587 1.534v11.666c0 .6-.196 1.111-.587 1.534a1.895 1.895 0 0 1-1.445.633H6.825c-.465 0-.894-.116-1.285-.35a3.197 3.197 0 0 1-1-.95L0 8Zm1.524 0 4.095 6c.127.178.296.333.508.467.212.133.444.2.698.2h11.143c.19 0 .366-.084.525-.251a.818.818 0 0 0 .237-.583V2.167a.818.818 0 0 0-.237-.583c-.16-.167-.334-.25-.525-.25H6.825c-.254 0-.486.066-.698.2-.212.133-.381.288-.508.466L1.524 8Z"
      fill="currentColor"
    />
  </svg>
);

export default MoreIcon;
