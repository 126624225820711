import styled from 'styled-components';
import { ProfilePicture as ProfilePictureComponent } from '../ProfilePicture';
import Link from 'next/link';

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Ellipsis = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ProfilePicture = styled(ProfilePictureComponent)``;

export const ProfileLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

export const Details = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
`;
