import { PropsWithChildren, ReactElement } from 'react';
import { Portal } from '@radix-ui/react-popover';

import {
  IconInnerWrapper,
  InnerContentWrapper,
  TooltipArrow,
  TooltipContent,
  TooltipIconWrapper,
  TooltipWrapper,
} from './Tooltip.styles';
import HintIcon from '../icons/HintIcon';
import Typography from '../Typography';

export type TooltipProps = {
  title?: string;
  content: string | ReactElement;
  className?: string;
  onOpenChange?: (open: boolean) => void;
  defaultOpen?: boolean;
  onCloseAutoFocus?: (event: Event) => void;
  disabled?: boolean;
  iconSize?: number;
};

const Tooltip = ({
  title,
  children,
  content,
  className,
  onOpenChange,
  defaultOpen,
  onCloseAutoFocus,
  disabled,
  iconSize = 20,
  ...otherProps
}: PropsWithChildren<TooltipProps>) => {
  return (
    <TooltipWrapper defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      <TooltipIconWrapper
        disabled={disabled}
        {...otherProps}
        className={className}
      >
        {!children && (
          <IconInnerWrapper>
            <HintIcon height={iconSize} width={iconSize} />
          </IconInnerWrapper>
        )}
        {children}
      </TooltipIconWrapper>
      <Portal>
        <TooltipContent onCloseAutoFocus={onCloseAutoFocus} arrowPadding={0}>
          <TooltipArrow />
          <InnerContentWrapper>
            {title && <Typography variant="ctaSmall">{title}</Typography>}
            {content}
          </InnerContentWrapper>
        </TooltipContent>
      </Portal>
    </TooltipWrapper>
  );
};

export default Tooltip;
