import type { HTMLAttributes } from 'react';
import { useMemo } from 'react';
import {
  PhonePictureContainer,
  PhonePictureFirstLetter,
  PhonePictureImg,
} from './ProfilePicture.styles';

export interface ProfilePictureProps extends HTMLAttributes<HTMLDivElement> {
  url?: string;
  firstName?: string;
}

const ProfilePicture = ({
  url,
  firstName,
  ...otherProps
}: ProfilePictureProps) => {
  const firstNameFirstLetter = useMemo(() => {
    if (firstName) {
      return firstName.slice(0, 1);
    }
    return '';
  }, [firstName]);

  return (
    <PhonePictureContainer {...otherProps}>
      {url && (
        <PhonePictureImg data-testid="profile-picture" src={url} alt="avatar" />
      )}
      {firstName && !url && (
        <PhonePictureFirstLetter data-testid="profile-username">
          {firstNameFirstLetter}
        </PhonePictureFirstLetter>
      )}
    </PhonePictureContainer>
  );
};

export default ProfilePicture;
