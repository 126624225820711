import { ReactNode } from 'react';
import Breadcrumb from 'styleguide/src/components/Breadcrumb';
import type { BreadcrumbsProps } from 'styleguide/src/components/Breadcrumb';
import { Container, Main } from './AppShell.styles';
import Sidebar from './Sidebar/Sidebar';
import { AccountRole } from 'common/types/AccountRole';

interface Props {
  children: ReactNode | undefined;
  // Immersive mode makes the app shell take up the entire screen without padding
  immersive?: boolean;
  breadcrumbs?: BreadcrumbsProps;
  navItems: Array<{
    title: string;
    href: string;
    icon: ReactNode;
    notification?: boolean;
    roleRestriction?: Array<AccountRole>;
  }>;
  baseUrl?: string;
  rootUrl?: string;
}

function AppShell({ children, immersive, breadcrumbs, navItems }: Props) {
  return (
    <Container>
      <Sidebar navItems={navItems} />
      <Main $immersive={immersive}>
        {breadcrumbs && <Breadcrumb {...breadcrumbs} />}
        {children}
      </Main>
    </Container>
  );
}

export default AppShell;
