import { ReactNode } from 'react';
import {
  Bread,
  BreadCrumbLink,
  BreadCrumbLinkButton,
  Container,
  Seperator,
} from './Breadcrumb.styles';

export type BreadcrumbPathProps = {
  to?: string;
  name: string | undefined;
  onClick?: () => void;
};

export type BreadcrumbsProps = {
  base: string;
  paths?: BreadcrumbPathProps[];
  seperator?: string | ReactNode;
};

const Breadcrumb = ({ base, paths, seperator }: BreadcrumbsProps) => {
  if (!base || paths?.length === 0) {
    return <></>;
  }
  return (
    <Bread>
      {paths?.map((path: BreadcrumbPathProps, index: number) => {
        return (
          <Container key={index}>
            {index !== 0 && <Seperator>{seperator ?? '/'}</Seperator>}
            {path?.to && (
              <BreadCrumbLink
                href={base + path.to}
                active={index === paths.length - 1}
              >
                {path.name}
              </BreadCrumbLink>
            )}
            {path?.onClick && (
              <BreadCrumbLinkButton
                active={index === paths.length - 1}
                onClick={path.onClick}
              >
                {path.name}
              </BreadCrumbLinkButton>
            )}
          </Container>
        );
      })}
    </Bread>
  );
};

export default Breadcrumb;
