import useResponsive from '../../../hooks/useResponsive';
import { NavBarProps } from '../NavBar/NavBar';
import { ProfileBarProps } from '../ProfileBar/ProfileBar';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

export interface NavProps extends ProfileBarProps {
  navLinks: NavBarProps['items'];
}

export default function Nav(props: NavProps) {
  const { isLarge } = useResponsive();

  if (isLarge) {
    return <DesktopNav {...props} />;
  }
  return <MobileNav {...props} />;
}
