import Link from 'next/link';
import { Container, LogoContainer, NavBarContainer } from './Nav.styles';
import Image from 'next/image';
import NavBar from '../NavBar/NavBar';
import ProfileBar from '../ProfileBar/ProfileBar';
import WrenoNavLogo from './WrenoNavLogo.svg';
import { NavProps } from './Nav';

export default function DesktopNav({
  navLinks,
  dropdownItems,
  isLoading,
  user,
  secondaryRole,
}: NavProps) {
  return (
    <Container>
      <LogoContainer>
        <Link href={navLinks[0]?.href || '/'}>
          <Image src={WrenoNavLogo} priority alt="Wreno Logo" />
        </Link>
      </LogoContainer>
      <NavBarContainer>
        <NavBar items={navLinks || []} />
      </NavBarContainer>
      <ProfileBar
        dropdownItems={dropdownItems}
        isLoading={isLoading}
        user={user}
        secondaryRole={secondaryRole}
      />
    </Container>
  );
}
