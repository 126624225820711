import { useMemo } from 'react';
import { useGetMyCompaniesQuery } from 'common/services/endpoints/company';
import { Company } from 'common/types/Company';
import useRoles from 'common/hooks/useRoles';
import useAccount from './useAccount';
import { CompanyManagerRole } from 'common/types/CompanyManager';

type Props = {
  skip?: boolean;
};

function getRoleMap(role?: CompanyManagerRole) {
  if (!role) {
    return {
      isCompanyAdmin: false,
      isCompanyTeamMember: false,
      isCompanyOwner: false,
    };
  }
  return {
    isCompanyAdmin: [CompanyManagerRole.COMPANY_ADMIN].includes(role),
    isCompanyTeamMember: [CompanyManagerRole.COMPANY_TEAM_MEMBER].includes(
      role,
    ),
    isCompanyOwner: [CompanyManagerRole.COMPANY_OWNER].includes(role),
  };
}

const useCompanyManagerRoles = ({ skip }: Props = {}) => {
  const { isLoading: roleLoading } = useAccount();
  const { isPropertyManager } = useRoles();

  const {
    data: propertyMangerCompanies,
    isLoading,
    error,
  } = useGetMyCompaniesQuery(undefined, {
    skip: skip || roleLoading || !isPropertyManager,
  });

  const companyManagerRole = useMemo(() => {
    return (propertyMangerCompanies as Company[])?.[0]?.companyManager
      ?.role as CompanyManagerRole;
  }, [propertyMangerCompanies]);

  return {
    isLoading,
    error,
    companyManagerRole,
    ...getRoleMap(companyManagerRole),
  };
};

export default useCompanyManagerRoles;
